import React from "react";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const RecentRegisterUsers = () => {
  const jobs = [
    { id: 1, title: "Software Developer", postedOn: "2024-09-28", responses: 10 },
    { id: 2, title: "Product Manager", postedOn: "2024-09-25", responses: 7 },
    { id: 3, title: "UI/UX Designer", postedOn: "2024-09-23", responses: 15 },
    // Add more jobs as needed
  ];

  return (
    <>
      <div className="recentCard">
        <div className="portTitle">
          <h5>Recently Posted Jobs</h5>
        </div>
        <div className="feeds">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Job Title</th>
                <th scope="col">Posted on</th>
                <th scope="col">Responses</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job, index) => (
                <tr key={job.id}>
                  <td>{index + 1}</td>
                  <td>{job.title}</td>
                  <td>{job.postedOn}</td>
                  <td>{job.responses}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="seeAll">
          <Link to="#">
            See All Recently Posted <BsFillArrowRightCircleFill />{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default RecentRegisterUsers;
