import React from "react";
import { FiSettings } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import PositionTable from "./positionTable/PositionTable";
import SideFilterComp from "./SideFilterComp";

function ListResume() {
  return (
    <div className="">
      <div className="">
        {/* <div className="pageTitle">
          <FiSettings />
          <h5>Resume List</h5>
        </div> */}
        {/* <div className="addNew">
          <Link to="#" className="btn btn-success">
            <GoPlus /> Add New Position Types
          </Link>
        </div> */}
      </div>

      <div className="pageBody row">
        <div className="col-md-3" style={{backgroundColor:"lightgray" ,borderRadius:"6px", padding:"10px 5px"}}>
          <SideFilterComp/>
        </div>
        <div className="col-md-9">
          <PositionTable />
        </div>
      </div>

      <div className="pageFooter">
        <div className="row">
          <div className="col-md-6">
            <div className="showEntry">
              <p>
                Showing 1 to 3 of 3 entries
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="paginationPart">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListResume;
