import React, { useState } from 'react';

const JobForm = () => {
    const [formData, setFormData] = useState({
        jobTitle: '',
        employmentType: '',
        workMood: '',
        jobDescription: '',
        keySkills: '',
        workExpFrom: '',
        workExpTo: '',
        salaryRangeFrom: '',
        salaryRangeTo: '',
        location: '',
        industry: '',
        candidateIndustry: '',
        department: '',
        referenceCode: '',
        vacancies: '',
        qualification: '',
        requestVideoProfile: false,
        includeWalkInDetail: false,
        companyName: '',
        clientName: '',
        clientDetail: '',
        questions: [''],
        notificationEmail: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...formData.questions];
        updatedQuestions[index] = value;
        setFormData({ ...formData, questions: updatedQuestions });
    };

    const addQuestion = () => {
        setFormData({ ...formData, questions: [...formData.questions, ''] });
    };

    const validate = () => {
        let errors = {};

        if (!formData.jobTitle) errors.jobTitle = 'Job title is required';
        if (!formData.employmentType) errors.employmentType = 'Employment type is required';
        if (!formData.workMood) errors.workMood = 'Work mood is required';
        if (!formData.jobDescription) errors.jobDescription = 'Job description is required';
        if (!formData.keySkills) errors.keySkills = 'Key skills are required';
        if (!formData.workExpFrom) errors.workExpFrom = 'Work experience (from) is required';
        if (!formData.workExpTo) errors.workExpTo = 'Work experience (to) is required';
        if (!formData.salaryRangeFrom) errors.salaryRangeFrom = 'Salary range (from) is required';
        if (!formData.salaryRangeTo) errors.salaryRangeTo = 'Salary range (to) is required';
        if (!formData.location) errors.location = 'Location is required';
        if (!formData.industry) errors.industry = 'Industry is required';
        if (!formData.candidateIndustry) errors.candidateIndustry = 'Candidate industry is required';
        if (!formData.department) errors.department = 'Department is required';
        if (!formData.referenceCode) errors.referenceCode = 'Reference code is required';
        if (!formData.vacancies) errors.vacancies = 'Number of vacancies is required';
        if (!formData.qualification) errors.qualification = 'Educational qualification is required';
        if (!formData.notificationEmail) errors.notificationEmail = 'Notification email is required';

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Handle form submission logic
            console.log('Form data submitted:', formData);
        }
    };

    return (
        <>
            <h4>Create Classified Job</h4>
            <form onSubmit={handleSubmit} className="container mt-4">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label>Job Title:</label>
                        <input type="text" name="jobTitle" className="form-control" value={formData.jobTitle} onChange={handleChange} />
                        {errors.jobTitle && <span className="text-danger">{errors.jobTitle}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Employment Type:</label>
                        <input type="text" name="employmentType" className="form-control" value={formData.employmentType} onChange={handleChange} />
                        {errors.employmentType && <span className="text-danger">{errors.employmentType}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Work Mood:</label>
                        <input type="text" name="workMood" className="form-control" value={formData.workMood} onChange={handleChange} />
                        {errors.workMood && <span className="text-danger">{errors.workMood}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Job Description:</label>
                        <textarea name="jobDescription" className="form-control" value={formData.jobDescription} onChange={handleChange} />
                        {errors.jobDescription && <span className="text-danger">{errors.jobDescription}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Key Skills:</label>
                        <input type="text" name="keySkills" className="form-control" value={formData.keySkills} onChange={handleChange} />
                        {errors.keySkills && <span className="text-danger">{errors.keySkills}</span>}
                    </div>

                    <div className="col-md-3 mb-3">
                        <label>Work Experience From:</label>
                        <input type="number" name="workExpFrom" className="form-control" value={formData.workExpFrom} onChange={handleChange} />
                        {errors.workExpFrom && <span className="text-danger">{errors.workExpFrom}</span>}
                    </div>

                    <div className="col-md-3 mb-3">
                        <label>Work Experience To:</label>
                        <input type="number" name="workExpTo" className="form-control" value={formData.workExpTo} onChange={handleChange} />
                        {errors.workExpTo && <span className="text-danger">{errors.workExpTo}</span>}
                    </div>

                    <div className="col-md-3 mb-3">
                        <label>Annual Salary Range From:</label>
                        <input type="number" name="salaryRangeFrom" className="form-control" value={formData.salaryRangeFrom} onChange={handleChange} />
                        {errors.salaryRangeFrom && <span className="text-danger">{errors.salaryRangeFrom}</span>}
                    </div>

                    <div className="col-md-3 mb-3">
                        <label>Annual Salary Range To:</label>
                        <input type="number" name="salaryRangeTo" className="form-control" value={formData.salaryRangeTo} onChange={handleChange} />
                        {errors.salaryRangeTo && <span className="text-danger">{errors.salaryRangeTo}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Location:</label>
                        <input type="text" name="location" className="form-control" value={formData.location} onChange={handleChange} />
                        {errors.location && <span className="text-danger">{errors.location}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Industry:</label>
                        <input type="text" name="industry" className="form-control" value={formData.industry} onChange={handleChange} />
                        {errors.industry && <span className="text-danger">{errors.industry}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Candidate Industry:</label>
                        <input type="text" name="candidateIndustry" className="form-control" value={formData.candidateIndustry} onChange={handleChange} />
                        {errors.candidateIndustry && <span className="text-danger">{errors.candidateIndustry}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Department:</label>
                        <input type="text" name="department" className="form-control" value={formData.department} onChange={handleChange} />
                        {errors.department && <span className="text-danger">{errors.department}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Reference Code:</label>
                        <input type="text" name="referenceCode" className="form-control" value={formData.referenceCode} onChange={handleChange} />
                        {errors.referenceCode && <span className="text-danger">{errors.referenceCode}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Number of Vacancies:</label>
                        <input type="number" name="vacancies" className="form-control" value={formData.vacancies} onChange={handleChange} />
                        {errors.vacancies && <span className="text-danger">{errors.vacancies}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Educational Qualification:</label>
                        <input type="text" name="qualification" className="form-control" value={formData.qualification} onChange={handleChange} />
                        {errors.qualification && <span className="text-danger">{errors.qualification}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Company Name:</label>
                        <input type="text" name="companyName" className="form-control" value={formData.companyName} onChange={handleChange} />
                        {errors.companyName && <span className="text-danger">{errors.companyName}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Client Name:</label>
                        <input type="text" name="clientName" className="form-control" value={formData.clientName} onChange={handleChange} />
                        {errors.clientName && <span className="text-danger">{errors.clientName}</span>}
                    </div>

                    <div className="col-md-12 mb-3">
                        <label>Client Detail:</label>
                        <textarea name="clientDetail" className="form-control" value={formData.clientDetail} onChange={handleChange}></textarea>
                        {errors.clientDetail && <span className="text-danger">{errors.clientDetail}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Request Candidate for Video Profile:</label>
                        <input type="checkbox" name="requestVideoProfile" checked={formData.requestVideoProfile} onChange={handleChange} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Include Walk-In Detail:</label>
                        <input type="checkbox" name="includeWalkInDetail" checked={formData.includeWalkInDetail} onChange={handleChange} />
                    </div>

                    <div className="col-md-12 mb-3">
                        <label>Questions for Candidate:</label>
                        {formData.questions.map((question, index) => (
                            <div key={index} className="d-flex mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={question}
                                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                                />
                                <button type="button" className="btn btn-secondary ml-2" onClick={addQuestion}>Add More</button>
                            </div>
                        ))}
                    </div>

                    <div className="col-md-12 mb-3">
                        <label>Email for Matching Application Notifications:</label>
                        <input type="email" name="notificationEmail" className="form-control" value={formData.notificationEmail} onChange={handleChange} />
                        {errors.notificationEmail && <span className="text-danger">{errors.notificationEmail}</span>}
                    </div>

                    <div className="col-12 mb-3">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default JobForm;
