import PageBar from "../../components/dashboard/page-bar/PageBar";
import ListResume from "../../components/list-resume/positionList/ListResume";

function ListResumePage() {
  return (
    <div className="pageWrapper">
      <PageBar title="List Resume" />
      <ListResume/>
    </div>
  );
}

export default ListResumePage;
