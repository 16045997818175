import React from "react";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import ResumeCard from "./ResumeCard";

function PositionTable() {
  const data = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
      address: "123 Main St, City, Country",
      skills: "Skill 1, Skill 2, Skill 3",
      education: "Bachelor's Degree in Computer Science",
      experience: "5 years of experience",
      resume: "Resume.pdf",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane.smith@example.com",
      phone: "987-654-3210",
      address: "456 Elm St, City, Country",
      skills: "Skill 4, Skill 5, Skill 6",
      education: "Master's Degree in Data Science",
      experience: "3 years of experience",
      resume: "Resume.pdf",
    },
  ]
  return (
    <div className="pageTable">
      <div className="tableLength">
        <div className="dataTables_length d-flex justify-content-between pageHeader" style={{ padding: "6px 0" }}>
          <div className="pageTitle">
            <FiSettings />
            <h5>Resume List</h5>
          </div>
          <div>
            <select className="form-select">
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span className="record">records</span>
          </div>
        </div>
      </div>
      {data?.map((resume) => (
        <ResumeCard resume={resume} />
      ))}
    </div>
  );
}

export default PositionTable;
