import React from 'react';
import './ManageJobs.css';
import { useNavigate } from 'react-router-dom';

const ManageJobsRes = () => {
  const navigate = useNavigate()
  const navigateroute = (id)=>{
      navigate(`/admin/aplication-list/${id}`)
  }
  return (
    <div className="manage-jobs-container">
      <div className="header">
        <h2>Manage Jobs and Responses</h2>
      </div>

      <div className="filters-and-jobs">
        <div className="filters">
          <input
            type="text"
            className="search-bar"
            placeholder="Search by Title/Ref Code/Job ID"
          />
          <div className="filter-section">
            <h4>Job status</h4>
            <label><input type="checkbox" /> Active Jobs</label>
            <label><input type="checkbox" /> Closed Jobs</label>
            <label><input type="checkbox" /> Expired Jobs</label>
          </div>

          <div className="filter-section">
            <h4>Category</h4>
            <label><input type="checkbox" /> Classified</label>
            <label><input type="checkbox" /> NVite</label>
            <label><input type="checkbox" /> Private</label>
          </div>
          <div className="filter-section">
            <h4>Job Posted By</h4>
            <label><input type="input" className='form-control' placeholder='ex:delhi@gmail.com'/> </label>
            
          </div>
        </div>

        <div className="jobs-list">
          <div className="job-controls">
            <span>All Jobs <strong>4</strong></span>
            <div className="actions">
              <button>Select All</button>
              <button>Refresh</button>
              <button>Collaborate</button>
              <button>Close</button>
            </div>
          </div>

          <div className="job-item" style={{cursor:"pointer"}} onClick={()=>{navigateroute(1)}}>
            <h3>Looking for PGT English - Rajasthan (Remote)</h3>
            <p>Total: 15 | Shortlisted: 0</p>
            <div className="job-meta">
              <span>Classified</span>
              <span>posted by Me: 03 Oct 2024</span>
              <span className="job-icons">
                <button>Edit</button>
                <button>View</button>
              </span>
            </div>
          </div>

          <div className="job-item" style={{cursor:"pointer"}} onClick={()=>{navigateroute(1)}}>
            <h3>Software Developer - Bangalore (Onsite)</h3>
            <p>Total: 20 | Shortlisted: 5</p>
            <div className="job-meta">
              <span>Urgent Hiring</span>
              <span>posted by Team: 02 Oct 2024</span>
              <span className="job-icons">
                <button>Edit</button>
                <button>View</button>
              </span>
            </div>
          </div>

          <div className="job-item" style={{cursor:"pointer"}} onClick={()=>{navigateroute(1)}}>
            <h3>Marketing Manager - Delhi (Hybrid)</h3>
            <p>Total: 10 | Shortlisted: 3</p>
            <div className="job-meta">
              <span>Open</span>
              <span>posted by Me: 01 Oct 2024</span>
              <span className="job-icons">
                <button>Edit</button>
                <button>View</button>
              </span>
            </div>
          </div>

          <div className="job-item" style={{cursor:"pointer"}} onClick={()=>{navigateroute(1)}}>
            <h3>Data Analyst - Pune (Remote)</h3>
            <p>Total: 18 | Shortlisted: 4</p>
            <div className="job-meta">
              <span>Classified</span>
              <span>posted by HR: 30 Sep 2024</span>
              <span className="job-icons">
                <button>Edit</button>
                <button>View</button>
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ManageJobsRes;
