import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
function SearchCandidate() {
    const [formData, setFormData] = useState({
        keywords: '',
        exclusiveKeywords: '',
        itSkills: '',
        experienceFrom: '',
        experienceTo: '',
        currentLocation: '',
        salaryFrom: '',
        salaryTo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
    };

    return (
        <div className="container">
            <h4>Search Candidate</h4>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="keywords">
                            <Form.Label>Keywords</Form.Label>
                            <Form.Control
                                type="text"
                                name="keywords"
                                value={formData.keywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="exclusiveKeywords">
                            <Form.Label>Exclusive Keywords</Form.Label>
                            <Form.Control
                                type="text"
                                name="exclusiveKeywords"
                                value={formData.exclusiveKeywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="itSkills">
                            <Form.Label>IT Skills</Form.Label>
                            <Form.Control
                                type="text"
                                name="itSkills"
                                value={formData.itSkills}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="experienceFrom">
                            <Form.Label>Experience (From)</Form.Label>
                            <Form.Control
                                type="number"
                                name="experienceFrom"
                                value={formData.experienceFrom}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="experienceTo">
                            <Form.Label>Experience (To)</Form.Label>
                            <Form.Control
                                type="number"
                                name="experienceTo"
                                value={formData.experienceTo}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="currentLocation">
                            <Form.Label>Current Location</Form.Label>
                            <Form.Control
                                type="text"
                                name="currentLocation"
                                value={formData.currentLocation}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="salaryFrom">
                            <Form.Label>Annual Salary (From)</Form.Label>
                            <Form.Control
                                type="number"
                                name="salaryFrom"
                                value={formData.salaryFrom}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="salaryTo">
                            <Form.Label>Annual Salary (To)</Form.Label>
                            <Form.Control
                                type="number"
                                name="salaryTo"
                                value={formData.salaryTo}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

            </Form>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Employment Details</Accordion.Header>
                    <Accordion.Body>
                       
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Education Details</Accordion.Header>
                    <Accordion.Body>
                       
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Diversity & Additional Detail</Accordion.Header>
                    <Accordion.Body>
                     
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


            
            <Button style={{margin:"10px 0"}} variant="primary" type="button">
                    Search
                </Button>
        </div>
    );
}

export default SearchCandidate;
