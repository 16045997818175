import React, { useState } from 'react';
import './CandidateList.css'; // Import the CSS file
import { Link } from 'react-router-dom';

const CandidateList = () => {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showPhone, setShowPhone] = useState({});

  const candidates = [
    {
      name: 'Darshani Sharma',
      designation: 'TGT English Teacher',
      company: 'GD Goenka inter...',
      skills: 'Geography / Teac...',
      location: 'New Delhi',
      status: 'Shortlist',
      phone: '123-456-7890',
    },
    {
      name: 'Rishabh Jain',
      designation: 'Alwar',
      company: 'AAA surgery cen...',
      skills: 'Personality and e...',
      location: 'Alwar',
      status: 'Shortlist',
      phone: '987-654-3210',
    },
    {
      name: 'Palak Bhagtani',
      designation: 'Teacher',
      company: 'No where',
      skills: 'Teaching English',
      location: 'Jaipur',
      status: 'Shortlist',
      phone: '456-789-0123',
    },
    // Add more candidates similarly...
  ];

  // Function to toggle a single candidate selection
  const handleCandidateSelection = (candidateName) => {
    if (selectedCandidates.includes(candidateName)) {
      setSelectedCandidates(selectedCandidates.filter(name => name !== candidateName));
    } else {
      setSelectedCandidates([...selectedCandidates, candidateName]);
    }
  };

  // Function to toggle 'select all' checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCandidates([]); // Deselect all
    } else {
      setSelectedCandidates(candidates.map(candidate => candidate.name)); // Select all
    }
    setSelectAll(!selectAll);
  };

  // Function to handle showing phone number
  const handleShowContactDetails = (candidateName) => {
    setShowPhone((prevShowPhone) => ({
      ...prevShowPhone,
      [candidateName]: !prevShowPhone[candidateName],
    }));
  };

  return (
    <div className="candidate-list">
      <h4>Looking for PGT English - Rajasthan (Remote)</h4>
      <div className="filter-bar">
        <button>All 15</button>
        <button>New responses 0</button>
        <button>Not viewed 14</button>
        <button>Action pending 15</button>
      </div>

      <table className="candidate-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>Candidate Name</th>
            <th>Designation</th>
            <th>Company Name</th>
            <th>Key Skills</th>
            <th>Phone Number</th>
            <th>Location</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {candidates.map((candidate, index) => (
            <tr key={index} className={selectedCandidates.includes(candidate.name) ? 'selected-row' : ''}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedCandidates.includes(candidate.name)}
                  onChange={() => handleCandidateSelection(candidate.name)}
                />
              </td>
              <td>{candidate.name}</td>
              <td>{candidate.designation}</td>
              <td>{candidate.company}</td>
              <td>{candidate.skills}</td>
              <td>
                {showPhone[candidate.name] ? (
                  <span>{candidate.phone}</span>
                ) : (
                  <button onClick={() => handleShowContactDetails(candidate.name)}>
                    Show contact details
                  </button>
                )}
              </td>
              <td>{candidate.location}</td>
              <td>{candidate.status}</td>
              <td>
                <button className="shortlist-btn">Shortlist</button>
                <button className="shortlist-btn" style={{margin:"0 6px", color:"white", backgroundColor:"ButtonHighlight"}}><Link to='/admin/candidate-detail/1'>View</Link></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CandidateList;
