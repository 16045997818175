import React from "react";
import PageBar from "./page-bar/PageBar";
import DashboardStats from "./dashboard-stats/DashboardStats";
import RecentRegisterJobs from "./recent-register-jobs/RecentRegisterJobs";
import RecentRegisterUsers from "./recent-register-users/RecentRegisterUsers";
const Dashboard = () => {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title="Expert Eye Recruiting Your Requirements Admin Panel" />
        <h3 className="page-title">
          Expert Eye Recruiting Dashboard{" "}
          <small>Expert Eye Recruiting Dashboard</small>{" "}
        </h3>
        <DashboardStats />
        <div className="recentDash">
          <div className="row">
            <div className="col-md-12">
              <RecentRegisterUsers />
            </div>
            <div className="col-md-12">
              <RecentRegisterJobs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
