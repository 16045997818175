import { useNavigate } from "react-router-dom";
import img from "../../../../assets/image/images/user.png"
import { IoIosWallet } from "react-icons/io";

const ResumeCard = ({ resume }) => {
    const navigate = useNavigate()
    const navigateroute = (id)=>{
        navigate(`/admin/candidate-detail/${id}`)
    }
    return (
        <div className="card" style={{ margin: "10px 0" , cursor:"pointer"}} onClick={()=>{navigateroute(1)}}>
            <div className="card-body d-flex">
                <div style={{ width: "150px" }}>
                    <img alt="image User" src={img} style={{ width: "100%" }} />
                </div>
                <div className="card-title">
                    <h5>{resume?.name}</h5>
                    <div><b>Ex: </b> 5y 0m
                        <IoIosWallet style={{ fontSize: "20px", marginLeft: "20px" }} /> :  ₹ 6 Lacs
                    </div>
                    <div><b>Location: </b> Noida , UP</div>
                    <div>
                    <b>Current: </b>
                        Node Js Developer at HyTech Professionals India Pvt Ltd
                        <br />
                        <b>Previous: </b>
                         
                        PHP Developer at VASI OLBUIZ INFOTECH Pvt Ltd
                    </div>
                    <div>
                    <b>Education </b>
                         :   B.Tech/B.E. Bhabha institute of Technology 2015
                    </div>
                    <div>
                    <b>Key skills : </b>
                         Node.js | Express | Sequelize | Node | Javascript | MySQL | Github | GIT | JIRA
                    </div>
                    <p>I am B.Tech graduate having 5 years of ex ,profile of Node.js developer. My skills JavaScript, Express, Node, MySQL,GIT,JIRA,Agile,S3</p>
                </div>
            </div>
        </div>
    );
}
export default ResumeCard;