import React from "react";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const RecentRegisterJobs = () => {
  const searches = [
    { id: 1, name: "John Doe", keyword: "React Developer", exp: "3 Years", location: "New York" },
    { id: 2, name: "Jane Smith", keyword: "Project Manager", exp: "5 Years", location: "Los Angeles" },
    { id: 3, name: "Alice Johnson", keyword: "UI/UX Designer", exp: "2 Years", location: "Chicago" },
    // Add more saved searches as needed
  ];

  return (
    <>
      <div className="recentCard">
        <div className="portTitle">
          <h5>Saved Searches</h5>
        </div>
        <div className="feeds">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Name</th>
                <th scope="col">Keyword</th>
                <th scope="col">Exp</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              {searches.map((search, index) => (
                <tr key={search.id}>
                  <td>{index + 1}</td>
                  <td>{search.name}</td>
                  <td>{search.keyword}</td>
                  <td>{search.exp}</td>
                  <td>{search.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="seeAll">
          <Link to="#">
            See All Saved Searches <BsFillArrowRightCircleFill />{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default RecentRegisterJobs;
